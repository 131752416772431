// extracted by mini-css-extract-plugin
export var eMarketplaceDevBenefitsSection = "F_gj";
export var eMarketplaceDevBusinessSection = "F_gc";
export var eMarketplaceDevCardBrdrIconSection = "F_f9";
export var eMarketplaceDevCaseSection = "F_gl";
export var eMarketplaceDevClientQuotesSection = "F_gn";
export var eMarketplaceDevExpertiseSection = "F_gg";
export var eMarketplaceDevFeaturesSection = "F_gb";
export var eMarketplaceDevIndustriesSection = "F_gd";
export var eMarketplaceDevOurAchievementsSection = "F_gm";
export var eMarketplaceDevProcessSection = "F_gf";
export var eMarketplaceDevProjLogoSection = "F_gk";
export var eMarketplaceDevServicesIconSection = "F_f8";
export var eMarketplaceDevTechStackSection = "F_gh";